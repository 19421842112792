import hideOnClickOutside from '../shared/click-outside'

document.addEventListener('turbolinks:load', () => {
  document
    .querySelector('#content-filters-toggle')
    ?.addEventListener('click', () => {
      openContentFilters()
    })

  document
    .querySelector('.js-close-filters-button')
    ?.addEventListener('click', () => {
      closeContentFilters()
    })

  document
    .querySelectorAll(
      '.filter-form #search-field, .content-filters #search-field-in-filter-menu'
    )
    ?.forEach((element) => {
      element.addEventListener('keyup', (e) => {
        syncContentFilterSearchFields(e)
      })
    })

  document
    .querySelectorAll(
      '.filter-form #seminar_category'
    )
    ?.forEach((element) => {
      element.addEventListener('change', (e) => {
        document
          .querySelectorAll(
            '.filter-form input[name="seminar_filter_categories[]"]'
          )
          ?.forEach((input) => {
            input.checked = false
          })
      })
    })

  checkOpenContentFiltersStorage()
})

window.deselectSelectbox = function deselect(elementId) {
  const selectbox = document.getElementById(elementId)
  if (selectbox) {
    selectbox.selectedIndex = -1
    selectbox.form.submit()
  }
}

const setOpenContentFiltersStorage = () => {
  sessionStorage.setItem(
    'contentFilters',
    JSON.stringify({
      path: window.location.pathname,
      open: true,
      params: window.location.search,
    })
  )
}

const checkOpenContentFiltersStorage = () => {
  let contentFilters = sessionStorage.getItem('contentFilters')

  if (contentFilters !== null) {
    contentFilters = JSON.parse(contentFilters)
    if (
      contentFilters.path &&
      contentFilters.path == window.location.pathname
    ) {
      if (
        contentFilters.open &&
        contentFilters.params !== window.location.search
      ) {
        openContentFilters({ animate: false })
      } else {
        sessionStorage.removeItem('contentFilters')
      }
    } else {
      sessionStorage.removeItem('contentFilters')
    }
  }
}

const openContentFilters = (options = {}) => {
  const filterContainer = document.querySelector('.content-filters')
  if (!filterContainer.classList.contains('show')) {
    setOpenContentFiltersStorage()
    document.querySelector('.content-filters-backdrop').classList.add('show')
    if (options.animate === undefined || options.animate == true) {
      filterContainer.classList.add('slide-in-out')
    }
    filterContainer.classList.add('show')
    hideOnClickOutside(filterContainer, {
      alsoHideElements: [document.querySelector('.content-filters-backdrop')],
      onCloseFunction: () => {
        document
          .querySelector('.content-filters')
          ?.classList?.add('slide-in-out')
        sessionStorage.removeItem('contentFilters')
      },
    })
    if (!documentHasEvent('keydown.filters')) {
      $(document).on('keydown.filters', function (e) {
        if (
          document
            .querySelector('.content-filters')
            ?.classList?.contains('show')
        ) {
          if (e.which == 27) {
            closeContentFilters()
          }
        }
      })
    }
  }
}

const closeContentFilters = () => {
  sessionStorage.removeItem('contentFilters')
  document.querySelector('.content-filters').classList.add('slide-in-out')
  document.querySelector('.content-filters').classList.remove('show')
  document.querySelector('.content-filters-backdrop').classList.remove('show')
  $(document).off('keydown.filters')
}

const syncContentFilterSearchFields = (e) => {
  document
    .querySelectorAll(
      '.filter-form #search-field, .content-filters #search-field-in-filter-menu'
    )
    .forEach((element) => {
      if (element != e.target) {
        element.value = e.target.value
      }
    })
}
