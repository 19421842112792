import PullToRefresh from 'pulltorefreshjs'

document.addEventListener('turbolinks:load', () => {
  if (window.mobileDevice == 'ios' && window.progressiveWebApp) {
    PullToRefresh.init({
      mainElement: 'body',
      iconArrow: '<i class="fa-solid fa-arrow-down"></i>',
      iconRefreshing: '<i class="fa-solid fa-spinner-third fa-spin"></i>',
      instructionsPullToRefresh: ' ',
      instructionsReleaseToRefresh: ' ',
      instructionsRefreshing: ' ',

      onRefresh() {
        Turbolinks.visit(window.location.href)
      },

      shouldPullToRefresh() {
        if (
          document.querySelector(
            'body.public-conversation.public-conversation-page > .public-conversation-content'
          ) ||
          document.querySelector(
            'body.controller-tickets.action-consume'
          )
        ) {
          return false
        } else {
          return !window.scrollY
        }
      },
    })
  }
})
